// Types
import { T2a, UserBlockProps } from "@/types";

// Services
import { T2AService } from "@/helpers/ClaimsGate/T2AService";

// Vue Instances
import { AddressInstance, getProps } from "./AddressInstance";
import { blocks } from "./data";
import {
  userIsAddressVerified,
  userIsElectoralRollVerified,
  userIsIdVerified,
} from "@/helpers/ClaimsGate/verification";
import { createAndStoreVerification } from "./createAndStoreVerification";
import { reject } from "@/helpers/vue";
import { getCountryByPostcodeFromPostcodeIo } from "./helpers";

export const methods = {
  emitSubmit,
  searchAddressByPostcode,
  allFieldsDefined,
  selectAddress,
  disableCompletedFields,
  postcodeSearchSuccess,
  setAddressManually,
  displayWholeLine,
  placeSelected,
  setLookupMode,
};

/**
 * Updates the address fields based on the selected place
 */
export function placeSelected(state: AddressInstance, place: any): void {
  console.log("placeSelected", place);
  state.user.addressLine1 = place.addressLine1;
  state.user.city = place.city;
  state.user.postcode = place.postcode;
  state.user.country = place.country;
}

function setAddressManually(state: AddressInstance): void {
  //state.uiToggles.addressSelected = true;
  state.uiToggles.manualEntrySelected = true;
  state.BlockInputs.addressLine1.disabled = false;
  state.BlockInputs.city.disabled = false;
}

function setLookupMode(state: AddressInstance): void {
  state.uiToggles.manualEntrySelected = false;
  state.BlockInputs.addressLine1.disabled = true;
  state.BlockInputs.city.disabled = true;
}

/**
 * Triggers a successful submit event to parent or form
 */
function fireSubmit(state: AddressInstance) {
  if (state.isChild) {
    // Let the parent component determin what to do next
    state.$emit("addressCompleted", state.user);
    state.uiToggles.hideSubmitButton = true;
    state.BlockInputs.nextBlockButton.isProcessing = false;
    state;
    return;
  }
  state.$store.dispatch("events/fire", { name: "next" });
  return;
}

/**
 * Runs electoral roll check if required, then Emits an event to form.vue or parent component indicating the page should be submitted or failed validations/computes
 */
export async function emitSubmit(state: AddressInstance) {
  try {
    console.log("Address.emitSubmit() called");
    state.BlockInputs.nextBlockButton.isProcessing = true;

    if (!validateInputs(state) || namesToShort(state)) {
      state.BlockInputs.nextBlockButton.isProcessing = false;
      console.log("Validation failed");
      return;
    }

    if (state.funnelId === "8TpwtHVXpDUdQvJ6YOFU") {
      state.user.country = "Ireland";
    }

    await setStateUserToDataStore(state);

    if (
      state.collectName === false ||
      (state.uiToggles.disabledOnMount && !state.checkElectoralRoll) || // Prefilled, no verifications necessarry
      userIsElectoralRollVerified(state.userData) || // User must've been verified by electoral roll previously
      (state.idFlowAllowsYoti && userIsIdVerified(state.userData) && userIsAddressVerified(state.userData)) // KYC parent allows yoti verification to pass (user must've passed in a previous funnel)
    ) {
      fireSubmit(state);

      return;
    }
    console.log("Address.emitSubmit() Checking electoral roll", state.checkElectoralRoll);
    if (!state.checkElectoralRoll) {
      await state.userDataService.update();
      await state.$nextTick();
      fireSubmit(state);
      return;
    }

    // If the user is international, and the funnel allows international clients, skip the electoral roll check
    if (state.allowInternationalClients && state.user.country && !isUkCountry(state.user.country)) {
      await state.userDataService.update();
      await state.$nextTick();

      fireSubmit(state);
      return;
    }
    console.log("Address.emitSubmit() Running T2A check");
    const [matchPassed] = await runT2aCheck(state);

    await state.userDataService.update();
    await state.$nextTick();
    console.log("Address.emitSubmit() T2A check complete", matchPassed);
    if (!matchPassed) {
      // Cannot continue with submission
      state.BlockInputs.nextBlockButton.isProcessing = false;

      if (!state.isChild) {
        await state.infoModalService.fire("info", {
          title: "No Person Found",
          text: state.uiMessages.electoralRoll.failed,
        });
      } else {
        state.$emit("t2aFailed", state.user);
      }

      return;
    }

    disableCompletedFields(state);

    state.BlockInputs.postcodeSearchBlock.disabled = true;

    state.BlockInputs.nextBlockButton.isProcessing = false;

    fireSubmit(state);
    return;
  } catch (exception) {
    console.error("Address.emitSubmit() threw", exception);
    state.BlockInputs.nextBlockButton.isProcessing = false;
    state;
  }
}

async function runT2aCheck(state: AddressInstance): Promise<[boolean]> {
  const { data, exception } = await T2AService.runElectoralRollCheck(
    state.userDataService.getCache() as T2a.RollCheckUserData,
    { claimId: state.claimId, funnelId: state.funnelId, pageId: state.pageId, userId: state.userService.getUserId() }
  );
  if (exception) {
    console.error("T2A check threw", exception);
  }
  const { matchPassed, electoralResult } = data;
  // Create a new veriification object
  await createAndStoreVerification(state, matchPassed, electoralResult);

  return [matchPassed];
}
/**
 * Check Postcode meets minimum length, call T2A, parse the reponse into Dropdown options
 */
export async function searchAddressByPostcode(state: AddressInstance) {
  try {
    startPostcodeSearchUI(state);

    const postcode = state.user.postcode;

    if (postcode?.length > 4) {
      let data: T2a.ParsedAddress[];
      let error: any, exception: any;
      // eslint-disable-next-line
      ({ data, error, exception } = await T2AService.runAddressSearch(postcode, {
        claimId: state.claimId,
        funnelId: state.funnelId,
        pageId: state.pageId,
        workspaceId: state.navigationWorkspaceId,
        userId: state.userService.getUserId(),
      }));

      if (error || exception) {
        console.error("T2A.runAddressSearch error", error, exception);
        await postcodeSearchError(state, true);
      } else {
        state.addressSearchResults = data;
        state.dropdownSelectAnswer = undefined;
        state.BlockInputs.addressSelectBlockDropdown.options = data.map((addr: T2a.ParsedAddress) => addr.text);
        postcodeSearchSuccess(state);
      }
    } else {
      await postcodeSearchError(state, true);
    }
  } catch (exception) {
    console.error("Postcode exception", exception);
    await postcodeSearchError(state, false);
  }
}

/**
 * Call setManyArtefacts to set the user data in the data store
 */
async function setStateUserToDataStore(state: AddressInstance) {
  console.log("Setting user data", state.user);

  console.log("Address.emitSubmit() country was", JSON.parse(JSON.stringify(state.user.country)));

  console.log("Address.emitSubmit() country is", JSON.parse(JSON.stringify(state.user.country)));

  [
    "title",
    "firstName",
    "middleName",
    "lastName",
    "addressLine1",
    "addressLine2",
    "city",
    "postcode",
    "country",
  ].forEach((field) => {
    if (state.user[field]) {
      state.userDataService.setArtefact(field, state.user[field]);
    }
  });

  // ! Defensive approach to prevent a potential issue arising with UK Electoral Roll
  if (!state.user.country) {
    state.user.country = "United Kingdom";
  }

  if (state.collectDateOfBirth) {
    console.log("Setting date of birth", state.user.dateOfBirth, state.dateService.parseDate(state.user.dateOfBirth));
    state.userDataService.setManyArtefacts({
      dateOfBirth: state.dateService.parseDate(state.user.dateOfBirth) ?? "",
    });
  }

  if (!state.user.country || state.user.country === "United Kingdom") {
    const jurisdiction = await getUkCountry(state.user.postcode);
    console.log(">>> Address Setting jurisdiction", jurisdiction);
    if (jurisdiction) state.userDataService.setManyArtefacts({ jurisdiction });
  }
}

/* Confirm all fields required have values  */
export function allFieldsDefined(state: AddressInstance): boolean {
  return state.requiredInputFields.every((field) => state.user[field] !== undefined && state.user[field] !== "");
}

/* Asserts if name fields are less than 2 char, if so sets state to false and provides relevant feedback to user */
function namesToShort(state: AddressInstance): boolean {
  if (!state.collectName) return false;
  if (state.user.firstName?.length < 2) {
    state.BlockInputs.firstName.state = false;
    state.BlockInputs.firstName.invalidFeedback = "First name must be longer than one character";
    reject(state, "First name must be longer than one character");
  }
  if (state.user.firstName?.length < 2) {
    state.BlockInputs.lastName.state = false;
    state.BlockInputs.lastName.invalidFeedback = "Last name must be longer than one character";
    reject(state, "Last name must be longer than one character");
  }

  return state.user.firstName?.length < 2 || state.user.lastName?.length < 2;
}

/**
 *  Set local user values from t2a response and
 */
export async function selectAddress(state: AddressInstance, event: string): Promise<void> {
  try {
    const address = state.addressSearchResults.find((addr) => addr.text === event)?.value;

    state.user.postcode = address?.postcode ?? "";
    state.user.addressLine1 = address?.addressLine1 ?? "";
    state.user.addressLine2 = address?.addressLine2 ?? "";
    state.user.city = address?.city ?? "";
    state.user.country = address?.country ?? "United Kingdom";

    await setStateUserToDataStore(state);

    state.uiToggles.addressSelected = true;
  } catch (exception) {
    console.log(exception);
    state.uiToggles.addressSelected = false;
    await state.infoModalService.fire("error", {
      text: state.uiMessages.postcodeSearch.lookupFailed,
    });
  }
}

/**
 * Loads values into input blocks and disables them.
 */
export function disableCompletedFields(state: AddressInstance): void {
  /* Fields can be disabled if complete, and, if electoral roll check is enabled
   * || allowDisable is still trae
   * || the user has been externally identified already
   **/

  blocks.forEach((field) => {
    if (state.user[field] && state.user[field] !== "" && field !== "title") {
      //state.BlockInputs[field].answer = state.user[field];

      state.BlockInputs[field].disabled = true;
    } else if (field === "title") {
      const titleOptions = state.BlockInputs.title.options;
      if (state.user.title) {
        if (titleOptions.includes(state.user.title)) {
          //state.BlockInputs[field].answer = state.user[field] as UserBlockProps.Titles;

          state.BlockInputs[field].disabled = true;
        } else {
          console.log("OPTIONS, current", titleOptions, state.user.title);
          //state.BlockInputs.title.answer = "Other";
          state.BlockInputs.other.answer = state.user.title;

          state.BlockInputs.title.disabled = true;
          state.BlockInputs.other.disabled = true;

          // this.user.titlnoe = "Other";
        }
      }
    }
  });

  // If date of birth validate
  if (state.user.dateOfBirth) {
    state.BlockInputs.dateOfBirth.answer = state.user.dateOfBirth;
    const dob = state.dateService.parseDate(state.BlockInputs.dateOfBirth.answer);

    const today = new Date();
    const age = today.getFullYear() - dob.getFullYear();

    // If age is less than min age
    if (age < parseInt(state.minAge)) {
      state.BlockInputs.dateOfBirth.disabled = false;
    }

    // If age is greater than max age
    if (age > parseInt(state.maxAge)) {
      state.BlockInputs.dateOfBirth.disabled = false;
    }
  }

  if (state.user.middleName) {
    state.BlockInputs.middleName.answer = state.user.middleName;

    state.BlockInputs.middleName.disabled = true;
  }
}

/**UI TRIGGERS */
function startPostcodeSearchUI(state: AddressInstance): void {
  resetPostcodeSearch(state);
  // state.dropdownSelectAnswer = "";
  state.uiToggles.searchingForPostcode = true;
}

export function postcodeSearchSuccess(state: AddressInstance): void {
  state.uiToggles.showPostcodeSearch = false;
  state.uiToggles.searchingForPostcode = false;
  state.uiToggles.postcodeSearchFailed = false;
  state.uiToggles.postcodeSearchSuccessful = true;
}

async function postcodeSearchError(state: AddressInstance, postcodeInvalid?: boolean): Promise<void> {
  state.uiToggles.showPostcodeSearch = false;
  state.uiToggles.searchingForPostcode = false;
  state.uiToggles.postcodeSearchFailed = true;
  state.uiToggles.postcodeSearchSuccessful = false;

  if (postcodeInvalid) {
    state.BlockInputs.postcodeSearchBlock.state = false;
    reject(state, "Your postcode was invalid, please try again.");
  }
}

function resetPostcodeSearch(state: AddressInstance): void {
  state.uiToggles.showPostcodeSearch = true;
  state.uiToggles.postcodeSearchFailed = false;
  state.uiToggles.searchingForPostcode = false;
  state.uiToggles.postcodeSearchSuccessful = false;
  state.uiToggles.searchedAddressSelected = false;
  state.uiToggles.addressSelected = false;
  state.BlockInputs.postcodeSearchBlock.state = null;
}

/** Resets the state of each of the blocks */
function resetInputStates(state: AddressInstance): void {
  [
    "postcodeSearchBlock",
    "addressLine1",
    "city",
    "postcode",
    "title",
    "middleName",
    "firstName",
    "lastName",
    "dateOfBirth",
    "other",
    "places",
  ].forEach((field) => {
    state.BlockInputs[field].state = null;
    state.BlockInputs.postcodeSearchBlock.invalidFeedback = "";
  });
}

/** Validates each of the inputs collected by the BlockAddress and sets error messages when invalid */
function validateInputs(state: AddressInstance): boolean {
  resetInputStates(state);
  const results = [];

  if (state.collectName) {
    // Validate title
    if (!state.user.title || state.user.title?.length === 0) {
      if (state.$props?.title === false) {
        state.BlockInputs.other.state = true;
        state.BlockInputs.title.state = true;
        results.push(true);
      } else if (state.titleAnswer === "Other") {
        state.BlockInputs.other.state = false;
        state.BlockInputs.other.invalidFeedback = "Please enter your title";
        reject(state, "Please enter your title");
        results.push(false);
      } else {
        state.BlockInputs.title.state = false;
        state.BlockInputs.title.invalidFeedback = state.uiMessages.titleMissing;
        reject(state, state.uiMessages.titleMissing);
        results.push(false);
      }
    } else {
      state.BlockInputs.other.state = true;
      state.BlockInputs.title.state = true;
      results.push(true);
    }

    // Validate first name
    if (!state.user.firstName || state.user.firstName?.length < 2) {
      state.BlockInputs.firstName.state = false;
      state.BlockInputs.firstName.invalidFeedback = state.uiMessages.firstNameTooShort;
      reject(state, state.uiMessages.firstNameTooShort);
      results.push(false);
    } else {
      state.BlockInputs.firstName.state = true;
      results.push(true);
    }

    // Validate last name
    if (!state.user.lastName || state.user.lastName?.length < 2) {
      state.BlockInputs.lastName.state = false;
      state.BlockInputs.lastName.invalidFeedback = state.uiMessages.lastNameTooShort;
      reject(state, state.uiMessages.lastNameTooShort);
      results.push(false);
    } else {
      state.BlockInputs.lastName.state = true;
      results.push(true);
    }
  }
  // Validate postcode
  if (!state.user.postcode || state.user.postcode.length === 0) {
    if (state.BlockInputs.livesInUkSelect.answer === "Yes") {
      state.BlockInputs.postcodeSearchBlock.state = false;
      state.BlockInputs.postcodeSearchBlock.invalidFeedback = state.uiMessages.postcodeMissing;
      reject(state, state.uiMessages.postcodeMissing);
      results.push(false);
    } else {
      if (!state.user.addressLine1 && !state.user.country) {
        state.BlockInputs.places.state = false;
        results.push(false);
        reject(state, state.uiMessages.addressMissing);
      } else {
        state.BlockInputs.places.state = true;
      }
      //Set places state true
    }
  } else {
    state.BlockInputs.postcodeSearchBlock.state = true;
    state.BlockInputs.addressLine1.state = true;
    state.BlockInputs.city.state = true;
    results.push(true);
  }

  if (state.uiToggles.manualEntrySelected) {
    if (!state.user.city || state.user.city.length === 0) {
      state.BlockInputs.city.state = false;
      state.BlockInputs.city.invalidFeedback = state.uiMessages.fieldRequired;
      reject(state, state.uiMessages.fieldRequired);
      results.push(false);
    }
    if (!state.user.addressLine1 || state.user.addressLine1.length === 0) {
      state.BlockInputs.addressLine1.state = false;
      state.BlockInputs.addressLine1.invalidFeedback = state.uiMessages.fieldRequired;
      reject(state, state.uiMessages.fieldRequired);
      results.push(false);
    }
  }

  if (state.uiToggles.restrictCountry) {
    if (state.user.addressLine1?.length === 0 || state.user.city?.length === 0) {
      state.BlockInputs.places.state = false;
      state.BlockInputs.places.invalidFeedback = state.uiMessages.addressOrCityMissing;
      reject(state, state.uiMessages.addressOrCityMissing);
      results.push(false);
    }

    if (state.user.addressLine1?.length === 0 && state.user.city?.length === 0) {
      state.BlockInputs.places.state = false;
      state.BlockInputs.places.invalidFeedback = state.uiMessages.addressMissing;
      reject(state, state.uiMessages.addressMissing);
      results.push(false);
    }
  }

  // Validate DOB
  if (state.collectDateOfBirth) {
    if (!state.user.dateOfBirth || (state.user.dateOfBirth as unknown as string)?.length === 0) {
      state.BlockInputs.dateOfBirth.state = false;

      results.push(false);
    } else if (state.requireAgeCheck) {
      const dob = state.dateService.parseDate(state.user.dateOfBirth);

      const today = new Date();
      const age = today.getFullYear() - dob.getFullYear();

      state.BlockInputs.dateOfBirth.state = null;
      state.BlockInputs.dateOfBirth.invalidFeedback = ``;
      // If age is less than the minAge
      if (age > parseInt(state.maxAge)) {
        state.BlockInputs.dateOfBirth.state = false;
        state.BlockInputs.dateOfBirth.invalidFeedback = `You must be less than ${state.maxAge} years old to use continue`;
        reject(state, `You must be less than ${state.maxAge} years old to use continue`);
        results.push(false);
      } else if (age < parseInt(state.minAge)) {
        state.BlockInputs.dateOfBirth.state = false;
        state.BlockInputs.dateOfBirth.invalidFeedback = `You must be at least ${state.minAge} years old to use continue`;
        reject(state, `You must be at least ${state.minAge} years old to use continue`);

        results.push(false);
      } else {
        state.BlockInputs.dateOfBirth.state = true;
        results.push(true);
      }
    } else {
      state.BlockInputs.dateOfBirth.state = true;
      results.push(true);
    }
  }

  // Validate vehicle finance
  if (state.collectVehicleFinanced) {
    if (state.BlockInputs.collectVehicleFinanced.answer !== "accepted") {
      state.BlockInputs.collectVehicleFinanced.state = false;
      state.BlockInputs.collectVehicleFinanced.invalidFeedback =
        "To be eligible for a claim, you must confirm that you had a vehicle on finance between 2007 and 2021 and were unaware of any commission payments to the dealer.";

      reject(
        state,
        "To be eligible for a claim, you must confirm that you had a vehicle on finance between 2007 and 2021 and were unaware of any commission payments to the dealer."
      );
      results.push(false);
    } else if (state.BlockInputs.collectVehicleFinanced.answer === "accepted") {
      state.BlockInputs.collectVehicleFinanced.state = true;
      state.BlockInputs.collectVehicleFinanced.invalidFeedback = "";
      results.push(true);
    }
  }

  if (state.funnelId === "8TpwtHVXpDUdQvJ6YOFU") {
    if (!state.user.addressLine1 || state.user.addressLine1?.length === 0) {
      state.BlockInputs.addressLine1.state = false;
      state.BlockInputs.addressLine1.invalidFeedback = "Please enter your address";
      reject(state, "Please enter your address");
      results.push(false);
    } else if (state.user.addressLine1.length < 3) {
      state.BlockInputs.addressLine1.state = false;
      state.BlockInputs.addressLine1.invalidFeedback = "Please enter a valid address";
      reject(state, "Please enter a valid address");
      results.push(false);
    } else {
      state.BlockInputs.addressLine1.state = true;
      results.push(true);
    }

    if (!state.user.city || state.user.city?.length === 0) {
      state.BlockInputs.city.state = false;
      state.BlockInputs.city.invalidFeedback = "Please enter your city";
      reject(state, "Please enter your city");
      results.push(false);
    } else if (state.user.city.length < 3) {
      state.BlockInputs.city.state = false;
      state.BlockInputs.city.invalidFeedback = "Please enter a valid city";
      reject(state, "Please enter a valid city");
      results.push(false);
    } else {
      state.BlockInputs.city.state = true;
      results.push(true);
    }

    if (!state.user.postcode || state.user.postcode?.length === 0) {
      state.BlockInputs.postcode.state = false;
      state.BlockInputs.postcode.invalidFeedback = "Please enter your postcode";
      reject(state, "Please enter your postcode");
      results.push(false);
    } else if (state.user.postcode.length < 6) {
      state.BlockInputs.postcode.state = false;
      state.BlockInputs.postcode.invalidFeedback =
        "Please enter a valid Irish Eircode, which is intended to be 7 characters long";
      reject(state, "Please enter a valid Irish Eircode");
      results.push(false);
    } else {
      state.BlockInputs.postcode.state = true;
      results.push(true);
    }
  }

  return results.every((r) => r);
}

/** Calculates number of name fields presented to the user to identify whether there is an even or odd number */
function displayWholeLine(state: AddressInstance): boolean {
  let numberOfNameFields = 2; // firstName and lastName are always collected
  if (state.middleName) numberOfNameFields++; // middleName is optional
  if (state.title) {
    if ((state.BlockInputs.title.answer === "Other" && state.allowOtherTitle) || state.displayOtherInput)
      numberOfNameFields += 2;
    else numberOfNameFields++;
  } // title is optional but can be either single dropdown or Other

  if (numberOfNameFields % 2 === 0) return false;
  return true;
}

async function getUkCountry(postcode: string): Promise<string> {
  let country: string;
  try {
    country = await getCountryByPostcodeFromPostcodeIo(postcode);
  } catch (exception) {
    console.error("Failed to get country from postcode", exception);
  }
  return country;
}

export function isUkCountry(country: string): boolean {
  return ["united kingdom", "england", "wales", "scotland", "northern ireland"].includes(country.toLowerCase());
}
