import { UserVariableService } from "@/helpers/ClaimsGate/UserVariableService";
import { Consent, User } from "@claimsgate/core-types";
import { BlockProps } from "@/types";
import { claimsGateTextPropValues } from "@/helpers/ClaimsGate/blocks/textProps";
import { UserDataService } from "@/helpers/ClaimsGate/DataService";
import { getUserHelper } from "@/helpers/ClaimsGate/UserHelper";
import firebase from "firebase/compat/app";
export function getData(_state: Vue) {
  return {
    checkboxes: {
      cgPrivacy: UserVariableService.getBlocks(["privacyConsent"]).privacyConsent as BlockProps.Checkbox,
      workspacePrivacy: UserVariableService.genBlockCheckboxProps({
        linkText: "Privacy Policy",
        linkTarget: "",
        text: "I agree to the",
      }),
      sms: UserVariableService.genBlockCheckboxProps({
        text: "Do you agree to receive SMS/Text messages?",
        linkTarget: "",
        linkText: "",
        invalidFeedback: "This field is required",
      }),
    },
    allConsents: { answer: "" } as BlockProps.Checkbox<"accepted" | "not_accepted" | "">,
    user: {} as User,
    userService: getUserHelper(),
    userDataService: undefined as UserDataService,
    claimsGateTsAndCsLink: `${window.location.origin}/legal/terms`,

    textProps: claimsGateTextPropValues,
    FieldValue: firebase.firestore.FieldValue,
    existingConsentsForThisWorkspace: {
      workspacePrivacyPolicyConsent: undefined as Consent,
      latestTsAndCsConsent: undefined as Consent,
    },
  };
}
