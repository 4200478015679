import { Props } from "@/types";

import { BlockAddressProps } from "./AddressInstance";

export function getProps(): Record<keyof BlockAddressProps, any> {
  return {
    collectName: {
      type: Boolean,
      default: true,
      description: "Whether to collect users name details.",
    } as Props.Bool,
    allowOtherTitle: {
      type: Boolean,
      default: true,
      description: "Allow user to enter a custom title",
    } as Props.Bool,
    collectVehicleFinanced: {
      type: Boolean,
      default: false,
      description: "If true, the block will collect the vehicle financed",
    } as Props.Bool,
    padding: {
      type: String,
      required: false,
      default: "10",
      description: "The padding above the block",
    } as Props.Str,
    checkElectoralRoll: {
      type: Boolean,
      required: false,
      default: true,
      description: "If true, the block runs as electoral roll check against the users name and address",
    } as Props.Bool,
    title: {
      type: Boolean,
      required: false,
      default: true,
      description: "If true, the block allows the user to enter their title",
    },
    middleName: {
      type: Boolean,
      required: false,
      default: true,
      description: "If true, the block allows the user to enter a middle name",
    } as Props.Bool,
    isChild: {
      type: Boolean,
      default: false,
      required: false,
      description:
        "If false the component will trigger the form next event, else it will emit a submit event for consumption by a parent component",
    } as Props.Bool,
    collectDateOfBirth: {
      type: Boolean,
      required: false,
      default: true,
      description: "If true, the block allows the user to enter a date of birth",
    } as Props.Bool,
    minAge: {
      type: String,
      description: "If the requireAgeCheck is true, this is the minimum age required of the user",
      default: "18",
    } as Props.CGPropExtras<string>,
    maxAge: {
      type: String,
      description: "If the requireAgeCheck is true, this is the maximum age required of the user",
      default: "100",
    } as Props.CGPropExtras<string>,
    requireAgeCheck: {
      type: Boolean,
      description: "If true, the user must be between the min and max age",
      default: false,
    } as Props.CGPropExtras<boolean>,
    idFlowAllowsYoti: {
      type: Boolean,
      description: "If true, the user is allowed to fallback to Yoti verifications",
      default: false,
    } as Props.CGPropExtras<boolean>,
    allowInternationalClients: {
      type: Boolean,
      description: "If true, the user can be from any country",
      default: false,
    } as Props.CGPropExtras<boolean>,
    preventUkPostcodeLookup: {
      type: Boolean,
      description: "If true, we will only allow clients to use the international address search",
      default: false,
    } as Props.CGPropExtras<boolean>,
    preventDefinedNameChange: {
      type: Boolean,
      description: "If true, the user cannot change their name if it is already defined",
      default: false,
    } as Props.CGPropExtras<boolean>,
    countryRestriction: {
      type: String,
      description: "If true, the country will be disabled",
      default: null,
    } as Props.CGPropExtras<string>,
  };
}
