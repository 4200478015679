import { ClaimDataService, UserDataService } from "@/helpers/ClaimsGate/DataService";
import { PageUtility } from "@/helpers/ClaimsGate/pages/PageUtility";
import { msg } from "@/helpers/ClaimsGate/ResponseMessageService";
import { FormRoute, MutatorFunction } from "@/types";
import {
  Action,
  Block,
  Claim,
  Intercom,
  Page,
  ReminderSequence,
  StandardAsyncResult,
  Workflow,
  Workspace,
} from "@claimsgate/core-types";
import { buildFunnelParamSlug } from "@/helpers/ClaimsGate/utils/buildFunnelParamSlug";

import { next } from "./next";

import { FormState } from "./BlockFormInstance";
import { createClaim, createClaimDataService, view } from "@/helpers/vue";
import {
  ClaimsGateErrors,
  ClaimsGateVariables,
  getFunnelVariables,
  getReminderSequences,
  SlaterGordon,
} from "@claimsgate/core";
import { getFirebaseBackend } from "@/authUtils";
import { runFunnelSpecificLogicOnMount } from "./methods";
import omitBy from "lodash.omitby";
import isNil from "lodash.isnil";

export async function mounted(state: FormState): Promise<void> {
  try {
    console.log("🚀 >>> mounted", state.funnelId, state.pageId, state.claimId);
    resetFunnelSearchEngineMetaData(state);

    const promises = setupInitalPromises(state);
    await initaliseFormVueModule(state);

    const promisesInitialResolved = await Promise.all(promises.initial);

    if (!state.funnelMeta) {
      state.funnelMeta = promisesInitialResolved[0].data;
      state.$store.dispatch("form/setFunnelMeta", state.funnelMeta);
    }

    // If the user does not have an account lets create an account for them
    if (!state.user) {
      state.user = promisesInitialResolved[1];
    }

    state.userId = state.userHelper.getUserId();

    if (!(await handleFunnelNotExist(state))) return;

    if (!(await handleSlaterGordonFunnel(state))) return;

    await handleNoClaimOrPageIdProvided(state, promisesInitialResolved[2]?.data);
    await handleNoPageIdProvided(state);

    setupFunnelSearchEngineMetaData(state);

    await validateFunnel(state);
    setupSecondaryPromises(state, promises);

    // Load in a cached version of the user's data
    setupUserDataService(state, promises);
    const promisesSecondaryResolved = await Promise.all(promises.secondary);

    setUserIdArtefact(state);

    const isValidClaim = await calculateIsValidClaim(state, promisesSecondaryResolved[0]?.data);

    if (isValidClaim === false) return;

    state.workspace = promisesSecondaryResolved[1];

    await fetchReminderSequences(state);

    handleWorkspaceDoesNotExist(state);

    console.log("🚀 >>> Create claim data service");

    if (!(await setupClaimDataService(state, isValidClaim, promisesInitialResolved[2]?.data))) return;

    console.log("🚀 >>> Created claim data service");

    await handleQueryParameterVariables(state);

    // Setup the appropriate tracking pixels for the funnel's author
    setupPixels(state, promises);

    // Reset previous intercom instance
    resetIntercom(state);

    await prepareInitialRoute(state, promisesInitialResolved[2]?.data);

    setupRepeatablePageProperties(state);

    if (state.$route.query.lang) {
      state.claimDataService.setArtefact("language", state.$route.query.lang);
    }

    await runFunnelSpecificLogicOnMount(state);

    handleWorkflowAssignmentDate(state);

    // Enable the UI
    state.loading = false;
  } catch (exception) {
    window.console.error(exception);
    await state.infoModalService.fire(
      "error",
      {
        title: msg.errors.unexpectedError.title,
        text: msg.errors.unexpectedError.text,
        confirmButtonText: "Redirect",
      },
      { route: state.$route }
    );

    await state.$router.push({ name: "Claims" });
  }
}

/** Handles the assignment of the workflowStartDate when assigned to claims which were already completed */
export function handleWorkflowAssignmentDate(state: FormState) {
  const workflow = state.claimDataService.getArtefact("workflow") as Workflow;

  if (!workflow) return;

  if (!workflow.dateStarted) {
    workflow.dateStarted = new Date();
    state.claimDataService.setArtefact("workflow", workflow);
  }
}

/** Fetches the total repeatable page iterations */
export function getPageTotalIterations(state: { claimDataService: ClaimDataService; page: Page }): number {
  const repeatableStoreAs = state.claimDataService?.getArtefact(state.page.repeatableStoreAs.value.id) ?? [];
  return repeatableStoreAs?.length;
}

/** Shuts intercom down and reboots it using the data in the UserDataService */
export function resetIntercom(state: { workspace: Workspace; userDataService: UserDataService; userId: string }) {
  if (window.intercom) {
    window.intercom.shutdown();

    // Fetch data to boot intercom
    const { workspace } = state;

    const email = state.userDataService.getArtefact("email");
    const firstName = state.userDataService.getArtefact("firstName");
    const lastName = state.userDataService.getArtefact("lastName");

    if (workspace.intercomAppId) {
      const intercomOptions: Intercom.IntercomOptions = { user_id: state.userId, app_id: workspace.intercomAppId };

      // If the user has an email we will attach it to intercom
      if (email) {
        intercomOptions.email = email;
      }

      // If the user has a first and last name we will attach their name to intercom
      if (firstName && lastName) {
        intercomOptions.name = `${firstName} ${lastName}`;
      }

      window.intercom.boot(intercomOptions);
    }
  }
}

/** Runs validations on the funnel's */
export async function validateFunnel(state: FormState) {
  const { authorId, isArchived, isPaused } = state.funnelMeta;

  if (!authorId) {
    throw new Error("Current claim does not have an owner");
  }

  if (isArchived || isPaused) {
    await state.infoModalService.fire("info", {
      text: msg.actions.redirecting,
      title: msg.funnels.archived,
    });
    if (state.navigationWorkspaceId) {
      await state.$router.push({ name: "WorkspaceClaims", params: { workspaceId: state.navigationWorkspaceId } });
    } else {
      await state.$router.push({ name: "Claims" });
    }
  }
}

/**
 * Prepares the next page by loading in any required from computes
 * @param {*} page - Next page
 */
export async function prepareNextPage(state: FormState, page: Page) {
  const { blocks } = page;

  if (!blocks) throw new Error("Page has no blocks!");
  // Initalise UI specific fields to help Vue Watchers

  // Append a property to keep track of Vuelidate state
  page.error = "";

  page = flattenPageBlocks(page);

  window.console.log("Page before prepare is...", page);
  // Load in existing answers from Firestore
  const t0 = performance.now();
  await state.parserService.prepareBlocks(page, state.claimId);

  window.console.log("[loadVariable] page after prepareblocks", JSON.parse(JSON.stringify(page)));
  const t1 = performance.now();
  window.console.warn(`PageHelper.prepareBlocks ${t1 - t0} milliseconds`);

  // Reset vuelidate validation
  // Reset page validation
  state.isError = false;
}

/** Sets up the funnel's search engine meta data */
export function setupFunnelSearchEngineMetaData(state: FormState) {
  state.$store.dispatch("meta/setTitle", state.funnelMeta?.title);
  state.$store.dispatch("meta/setDescriptions", state.funnelMeta?.description);
}

/** Resets the funnel's search engine meta data */
export function resetFunnelSearchEngineMetaData(state: FormState) {
  state.$store.dispatch("meta/reset");
}

/**
 * Locates the page from a passed identifier
 */
export async function getPageById(state: FormState, pageId: string, funnelId: string) {
  const { data: pageData } = await state.funnelsService.getPage(funnelId, pageId, true);
  const { data: page } = await state.pageHelper.getPage(pageId, funnelId, pageData);

  if (!page) {
    await state.infoModalService.fire(
      "error",
      {
        title: msg.errors.resourceDoesNotExist.title,
        text: msg.errors.resourceDoesNotExist.text,
        confirmButtonText: "Redirect",
      },
      { route: state.$route }
    );
    state.$router.push({ name: "Track", params: { claimId: state.claimId } });

    return null;
  }

  return JSON.parse(JSON.stringify(page));
}

export async function setupClaimDataService(
  state: FormState,
  isValidClaim: boolean,
  firstRoute: { funnelId: string; pageId: string }
): Promise<boolean> {
  // Load in a cached version of the users claim data
  if (state.claimId) {
    // If the given claim identiifer is not valid
    // We will send the users to the home page since we can be pretty certain they
    // likely have an active claim
    if (!isValidClaim) {
      state.claimId = null;
      state.claimDataService = null;

      await routeToFirstPage(state, firstRoute);

      createClaimDataService(state);
      state.pageId = firstRoute.pageId;
      view(state);
      await createClaim(state);
      return true;
    }

    state.claimDataService = new ClaimDataService(state.userId, state.claimId);
    const { data: hasClaimData } = await state.claimDataService.refresh();

    if (!hasClaimData) {
      state.claimId = null;
      state.claimDataService = null;

      await routeToFirstPage(state, firstRoute);
      console.log("<><> TRYING TO FORCE TO FIRST PAGE");
      createClaimDataService(state);
      state.pageId = firstRoute.pageId;
      view(state);
      await createClaim(state);
      return true;
    }

    if (state.claimDataService.getArtefact("claimStatus") === "paused") {
      await state.infoModalService.fire("error", {
        title: "Claim Paused",
        text: "Your claim is currently paused, which means you cannot proceed with it right now. If you need assistance or think this might be a mistake, feel free to reach out directly to the law firm.",
      });
      await state.$router.push({
        name: "Claims",
        params: { funnelId: state.funnelId, pageId: state.pageId },
        query: { ...state.$route.query },
      });
      return false;
    }
    if (state.claimDataService.getArtefact("claimStatus") === "deleted") {
      await state.infoModalService.fire("error", {
        title: "Claim Deleted",
        text: "Your claim has been deleted. If you think this is a mistake, please contact the law firm directly for assistance.",
      });
      await state.$router.push({
        name: "Claims",
        params: { funnelId: state.funnelId, pageId: state.pageId },
        query: { ...state.$route.query },
      });
      return false;
    }

    // push to track page is clientClaimProgress  == review
    if (state.claimDataService.getArtefact("clientClaimProgress") === "review") {
      // pop info modal to say claim is in review
      await state.infoModalService.fire("info", {
        title: "This claim is in review.",
        text: "You will be redirected to the track page where you can view the status of the review.",
      });
      await state.$router.push({
        name: "Track",
        params: { claimId: state.claimId },
      });
    }
    view(state);
  } else {
    createClaimDataService(state);
    view(state);
    await createClaim(state);
  }

  return true;
}

/** Handles the logic when the given funnel identifier does not exist */
export async function handleFunnelNotExist(state: FormState): Promise<boolean> {
  if (!state.funnelMeta) {
    // If the funnel does not exist, we will need to return the user back to the
    // claims page since we can not guarantee that they won a valid claim.
    await state.infoModalService.fire(
      "error",
      {
        title: msg.errors.resourceDoesNotExist.title,
        text: msg.errors.resourceDoesNotExist.text,
        confirmButtonText: "Redirect",
      },
      { route: state.$route }
    );

    if (state.navigationWorkspaceId) {
      await state.$router.push({ name: "WorkspaceClaims", params: { workspaceId: state.navigationWorkspaceId } });
    } else {
      await state.$router.push({ name: "Claims" });
    }

    return false;
  }

  return true;
}

/** Redirects a user to their current page when no page identifier has been given */
export async function handleNoPageIdProvided(state: FormState) {
  // If the user has not provided a specific page to modify or complete, fetch the users next page
  if (state.claimId && !state.pageId) {
    const currentPageId = state.claimDataService.getArtefact("currentPageId");
    state.router.acceptNewRoute(currentPageId);
    state.pageId = currentPageId;
  }
}
/** Redirects a user to the first route of a funnel when no claim or page identifier has been provided */
export async function handleNoClaimOrPageIdProvided(
  state: FormState,
  firstRoute: { funnelId: string; pageId: string }
) {
  if (!state.claimId && !state.pageId) {
    state.router.acceptNewRoute(firstRoute);

    // If the page was originally accessed with a slug, we want to keep it in the path param for the funnelId
    //const funnelId = buildFunnelParamSlug(firstRoute.funnelId, state.funnelSlug);

    await state.$router.push({
      name: "form",
      params: { funnelId: firstRoute.funnelId, pageId: firstRoute.pageId },
      query: { ...state.$route.query },
    });

    state.pageId = firstRoute.pageId;
  }
}

/** Force to first page */
export async function routeToFirstPage(state: FormState, firstRoute: { funnelId: string; pageId: string }) {
  state.router.acceptNewRoute(firstRoute);
  await state.$router.push({
    name: "form",
    params: { funnelId: firstRoute.funnelId, pageId: firstRoute.pageId },
    query: { ...state.$route.query },
  });
}

/**
 * Invokes router to resolve the next page
 * to display
 * @param page The current page
 * @returns The next page to render or undefined if there is no
 *          next page
 */
export async function getNextPage(state: FormState) {
  if (!state.router.hasNextRoute()) {
    return undefined;
  }

  const { pageId, funnelId } = state.router.getNextRoute();

  window.console.log("Next route information returned by router", pageId, funnelId);

  return getPageById(state, pageId, funnelId);
}

export async function calculateIsValidClaim(state: FormState, claims: Array<Claim>) {
  if (claims && claims.length > 0) {
    // If the selected claim already exists in the database, we will allow the user to proceed.
    const selectedClaimAlreadyExists = claims.find(
      (claim) => claim.currentFunnelId === state.funnelId && claim.claimId === state.claimId
    );

    // If the user is trying to crate a testClaim, we will allow them to proceed
    if (state.$route.query.testClaim === null && state.funnelMeta.isRepeatable === false) {
      return true;
    }

    if (selectedClaimAlreadyExists && state.funnelMeta.isRepeatable === false) {
      return true;
    }

    // Check if the user has another claim for this funnel, which is not the current claim
    // The criteria for this is that the claim must be active and have a completion action
    const existingClaim = claims.find(
      (claim) =>
        claim.currentFunnelId === state.funnelId &&
        claim.claimId !== state.claimId &&
        claim.claimStatus === "active" &&
        claim?.actions?.some((action) => action.kind === "completion")
    );

    const claimId = existingClaim?.claimId;

    if (existingClaim && state.funnelMeta.isRepeatable === false) {
      console.log(">>> state.funnelMeta.isRepeatable", state.funnelMeta.isRepeatable);

      await state.infoModalService.fire("info", {
        title: "You have already submitted a claim for this service.",
        text: "Please press 'Redirect' to be redirected to view the claim you have already submitted.",
        dismissButtonText: "Redirect",
      });

      if (state.navigationWorkspaceId) {
        await state.$router.replace({
          name: "Track",
          params: { workspaceId: state.navigationWorkspaceId, claimId },
        });
      } else {
        await state.$router.replace({ name: "Claims" });
      }

      return false;
    }
  }

  return true;
}

/** Initalizes the tracking pixels for the current originator or funnel author */
function setupPixels(state: FormState, promises: { initial: any[]; secondary: any[]; fireAndforget: any[] }) {
  // Fetch the originator from local storage
  const referral = state.workspaceReferralService.getReferralFromLocalStorage(localStorage);

  console.log(">>> Referral", referral, state.claimDataService.getArtefact("referrerId"));
  if (state.claimDataService.getArtefact("referrerId")) {
    promises.fireAndforget.push(
      state.pixelInitalisationService.initPixels(
        state.claimDataService.getArtefact("referrerId"),
        state.funnelId,
        state.claimDataService
      )
    );
  }

  promises.fireAndforget.push(
    state.pixelInitalisationService.initPixels(state.funnelMeta.authorId, state.funnelId, state.claimDataService)
  );
}

/** Sets up the user data service */
function setupUserDataService(state: FormState, promises: { initial: any[]; secondary: any[]; fireAndforget: any[] }) {
  if (state.userId) {
    state.userDataService = new UserDataService(state.userId);
    promises.secondary[2] = state.userDataService.refresh();
  }
}

/** Sets the currently authenicated user identifier on the User Data Service */
function setUserIdArtefact(state: FormState) {
  if (state.userId) {
    state.userDataService.setArtefact("userId", state.userId);
  }
}

/** Handles the logic when the funnel author (a workspace) does not exist */
export async function handleWorkspaceDoesNotExist(state: FormState) {
  if (!state.workspace) {
    await state.infoModalService.fire("info", {
      text: msg.actions.redirecting,
      title: msg.errors.pageLoadFailed,
    });
    if (state.navigationWorkspaceId) {
      await state.$router.push({ name: "Track", params: { workspaceId: state.navigationWorkspaceId } });
    } else {
      await state.$router.push({ name: "Claims" });
    }
  }
}

/** Sets up properties on the current page if it is repeatable */
function setupRepeatablePageProperties(state: FormState) {
  if (state.page?.isRepeatable) {
    state.page.currentIteration = 0;
    state.page.totalIterations =
      getPageTotalIterations({ claimDataService: state.claimDataService, page: state.page }) ?? 1;
  }
}

/** Handles the logic for storage and auto submission of variables which have been passed in query parameters */
export async function handleQueryParameterVariables(state: FormState) {
  if (Object.keys(state.$route.query)?.length > 0) {
    if (!state.claimDataService) {
      createClaimDataService(state);
    }

    const [funnelVariables, _getFunnelVariables] = await getFunnelVariables(
      getFirebaseBackend().firestore(),
      state.funnelId
    );

    const hashedQueryEntries = omitBy(await state.variableService.hashData(state.$route.query, funnelVariables), isNil);

    for (const [key, value] of Object.entries(hashedQueryEntries)) {
      state.claimDataService.setArtefact(key, value);
    }
  }
}

/** Loads in the requested page if it has been completed or the route is the user's next page */
async function handleRequestedPageCompletedOrCurrent(state: FormState) {
  // Load the requested page if it has been completed or the route is the user's next page
  if (state.claimId && state.pageId) {
    const t0 = performance.now();

    const currentPageId = state.claimDataService.getArtefact("currentPageId");
    const currentFunnelId = state.claimDataService.getArtefact("currentFunnelId");

    const t1 = performance.now();
    window.console.warn(`getClaimData ${t1 - t0} milliseconds`);

    // If the user has a currentPageId, currentFunnelId and trail then they have completed at least
    // a single step in the funnel
    if (currentPageId && currentFunnelId) {
      const actions: Array<Action> = state.claimDataService.getArtefact("actions");

      const hasCompletedRequestedPage = actions.some(
        (action) => action.kind === "completion" && action.pageId === state.pageId
      );

      // If the requested page has been completed and that page is the current page
      if (hasCompletedRequestedPage && currentPageId === state.pageId) {
        // Check if there has been a funnel change which would require a redirect
        const page: Page = await getPageById(state, state.pageId, state.funnelId);

        // If there was a new page added to the funnel then redirect to that page
        if (page.next?.pageId && !page.isStart) {
          state.router.acceptNewRoute({ pageId: page.next.pageId, funnelId: state.funnelId });
          state.pageId = page.next.pageId;
        } else {
          state.router.acceptNewRoute({ pageId: state.pageId, funnelId: currentFunnelId });
        }
      } else {
        state.router.acceptNewRoute({ pageId: state.pageId, funnelId: currentFunnelId });
      }
    }
  }
}

/** Handles the logic when the requested page is not not completed or the current page */
export async function handleRequestedPageIsNotCompletedOrCurrent(state: FormState) {
  let nextPage;

  // const trail = state.claimDataService.getArtefact("trail");
  const currentPageId = state.claimDataService.getArtefact("currentPageId");

  //let currentPageId;
  let trail;

  if (
    (state.claimId && currentPageId !== state.pageId) ||
    (trail && !trail.find((previous) => previous.pageId === state.page.id))
  ) {
    nextPage = await getPageById(state, currentPageId, state.funnelId);
  } else {
    nextPage = await getNextPage(state);
  }

  if (nextPage) {
    await prepareNextPage(state, nextPage);
  }
  console.log("Loaded page is", nextPage);
  // Load the next page on UI
  state.page = nextPage;
}

/** Tries to determine and load the user's next route */
export async function handleRouteValidations(state: FormState, firstRoute: { funnelId: string; pageId: string }) {
  // If the user does not already have a claim, then load the default requested page
  // If the user has a claim but we were still unable to determine the user's next route
  if ((state.pageId && !state.claimId) || (state.pageId && state.claimId && !state.router.hasNextRoute())) {
    // Check if the requested page ID valid
    const { data: isValidPageId } = await state.funnelsService.isValidPage(state.funnelId, state.pageId);

    if (!isValidPageId) {
      await state.infoModalService.fire(
        "error",
        {
          title: msg.errors.resourceDoesNotExist.title,
          text: msg.errors.resourceDoesNotExist.text,
          confirmButtonText: "Redirect",
        },
        { route: state.$route }
      );

      if (state.navigationWorkspaceId) {
        await state.$router.push({ name: "WorkspaceClaims", params: { workspaceId: state.navigationWorkspaceId } });
      } else {
        await state.$router.push({ name: "Claims" });
      }
      return;
    } else {
      // Check if it is possible to load the next page - given the user has not provided a claim
      const { data: nextPage } = await state.funnelsService.getPage(state.funnelId, state.pageId, true);

      // If the next page is not the start page
      if (!nextPage.isStart) {
        console.log(">>>> nextPage", nextPage, firstRoute);
        state.router.acceptNewRoute(firstRoute);

        // If the page was originally accessed with a slug, we want to keep it in the path param for the funnelId
        const funnelId = buildFunnelParamSlug(firstRoute.funnelId, state.funnelSlug);

        await state.$router.push({
          name: "form",
          params: { funnelId: funnelId, pageId: firstRoute.pageId },
          query: { ...state.$route.query },
        });

        state.pageId = firstRoute.pageId;

        return;
      }

      // Otherwise we can add the route
      state.router.acceptNewRoute({ pageId: state.pageId, funnelId: state.funnelId });
    }
  }
}

function setupSecondaryPromises(state: FormState, promises: FormMountPromises) {
  if (!state.funnelMeta.isRepeatable || state.claimId) {
    promises.secondary[0] = state.userHelper.getClaimsByFunnelId(state.funnelId);
  }

  promises.secondary[1] = state.$store.dispatch("navigation/setWorkspace", {
    workspaceId: state.funnelMeta.authorId,
    useCache: true,
  });

  promises.secondary[2] = getReminderSequences(getFirebaseBackend().firestore(), state.funnelId);
}

interface FormMountPromises {
  initial: [
    Funnel: ReturnType<FormState["funnelsService"]["getFunnelMeta"]>,
    AuthUser: Promise<any>,
    PageAndFunnelId: ReturnType<FormState["funnelsService"]["getFirstPage"]>
  ];
  secondary: [
    UsersClaims: Promise<StandardAsyncResult<Array<Claim>>>,
    Workspace: Promise<Workspace>,
    ReminderSequences: Promise<[Array<ReminderSequence> | null, ClaimsGateErrors.DataMissing | null]>
  ];
  fireAndforget: [any, any];
}
/** Fetches initial data points about the funnel anmd creates an anonymous user in Firebase Authentication if not already defined. */
function setupInitalPromises(state: FormState): FormMountPromises {
  // Secondary promises are those dependant on the data of the inital promises
  // Promise.all maintains order, so the `null` values act as placeholders for expected values
  const promises: FormMountPromises = {
    initial: [null, null, null],
    secondary: [null, null, null],
    fireAndforget: [null, null],
  };
  if (state.funnelMetaCache && state.funnelMetaCache.id === state.funnelId) {
    state.funnelMeta = state.funnelMetaCache;
  } else {
    promises.initial[0] = state.funnelsService.getFunnelMeta(state.funnelId);
  }

  state.user = state.userHelper.getUser();
  if (!state.user) {
    promises.initial[1] = state.$store.dispatch("auth/anonLogIn");
  }

  promises.initial[2] = state.funnelsService.getFirstPage(state.funnelId);

  return promises;
}

/** Prepares the inital route by running validations and loading it into the state */
export async function prepareInitialRoute(state: FormState, firstRoute: { funnelId: string; pageId: string }) {
  await handleRequestedPageCompletedOrCurrent(state);
  await handleRouteValidations(state, firstRoute);

  // If the user is requesting a page which is not completed or their current page
  await handleRequestedPageIsNotCompletedOrCurrent(state);
}

/**
 * Parses the page to get the blocks out for the form to use. No more containers, rows, cols
 */
export function flattenPageBlocks(page: Page): Page {
  const { blocks } = page;
  const pageBlocks: Array<Block> = [];
  blocks.forEach((container) => {
    if (container?.rows?.length) {
      container.rows.forEach((row) => {
        if (row?.cols?.length) {
          row.cols.forEach((col) => {
            if (col?.blocks?.length) {
              pageBlocks.push(...col.blocks);
            }
          });
        }
      });
    } else if (!["Column", "Container", "Row"].includes((container as Block).type)) {
      // Blocks may already have been flattened
      pageBlocks.push(container as Block);
    }
  });

  page.blocks = pageBlocks.map((block) => {
    return { ...block, isVisible: true };
  });

  return page;
}

/** Initalizers for the form Vue X module */
async function initaliseFormVueModule(state: FormState) {
  await state.$store.dispatch("form/initalize");
}

/** Fetches the reminder sequences associated with this funnel */
async function fetchReminderSequences(state: FormState) {
  const [reminderSequences, getReminderSEquencesError] = await getReminderSequences(
    getFirebaseBackend().firestore(),
    state.funnelId
  );

  if (reminderSequences && !getReminderSEquencesError) state.reminderSequences = reminderSequences;
}

/** Prevents users on the Slater and Gordon UK funnel from creating new claims */
async function handleSlaterGordonFunnel(state: FormState) {
  // If the query parameters claimId is not present and the funnel is Slater Gordon

  if (
    !state.claimId &&
    state.funnelId === SlaterGordon.Funnels.MERCEDES_FUNNEL_ID &&
    state.$route.query.testClaim !== null
  ) {
    const claims = await state.userHelper.getClaimsByFunnelId(state.funnelId);

    if (claims.data.length === 0) {
      await state.infoModalService.fire("info", {
        text: "Please press 'Understood' to be redirected to view your claims.",
        title: "We are no longer accepting new claims for this matter.",
      });

      await state.$router.push({ name: "WorkspaceClaims", params: { workspaceId: state.navigationWorkspaceId } });

      return false;
    }

    return true;
  }

  return true;
}
