import { Checkboard } from "@claimsgate/core-types";

export function getUserDetailsFromCheck(check: Checkboard.Check) {
  if (!check.userData) {
    return {
      firstName: "",
      lastName: "",
      dob: "",
      addressLine1: "",
      addressLine2: "",
      country: "",
      postcode: "",
      city: "",
    };
  }
  return {
    title: check.userData.title,
    firstName: check.userData.firstName,
    lastName: check.userData.lastName,
    dob: check.userData.dob,
    addressLine1: check.userData.addressLine1,
    addressLine2: check.userData.addressLine2,
    country: check.userData.country,
    postcode: check.userData.postcode,
    city: check.userData.city,
  };
}
