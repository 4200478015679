<script lang="ts">
import Vue from "vue";
import { themesComputed } from "@/state/helpers";
import * as RegularIcons from "@fortawesome/pro-regular-svg-icons";

import BlockTitle from "@/components/shared/blocks/text/title.vue";
import BlockSubtitle from "@/components/shared/blocks/text/subtitle.vue";
import BlockPadding from "@/components/shared/blocks/padding.vue";

interface FormattedSection {
  title: string;
  text: string;
  icon: any; // or proper FontAwesome icon type
}

export default Vue.extend({
  name: "BlockInfoCard",
  components: {
    BlockTitle,
    BlockSubtitle,
    BlockPadding,
  },

  props: {
    padding: {
      type: String,
      default: "10",
    },
    title: {
      type: String,
      default: "Car Finance Claims Finder",
    },
    subtitle: {
      type: String,
      default:
        "Find out if you have any mis-sold car finan;ce agreements, that could qualify for up to £3,000 in compensation each.",
    },
    subtitle2: {
      type: String,
      default: "",
    },

    section1Title: { type: String, default: "Enter Your Details" },
    section1Text: { type: String, default: "Add your name and address to get started." },
    section1Icon: { type: String, default: "faShieldCheck" },
    section2Title: { type: String, default: "Quick Verification" },
    section2Text: {
      type: String,
      default: "We'll check your details against the list of mis-sold car finance agreements.",
    },
    section2Icon: { type: String, default: "faFileMagnifyingGlass" },
    section3Title: {
      type: String,
      default: "Your Results",
    },
    section3Text: {
      type: String,
      default: "We'll review your finance agreements to determine how much compensation you could be owed.",
    },
    section3Icon: { type: String, default: "faCheckSquare" },
    section4Title: { type: String, default: "" },
    section4Text: { type: String, default: "" },
    section4Icon: { type: String, default: "faCircleInfo" },
    section5Title: { type: String, default: "" },
    section5Text: { type: String, default: "" },
    section5Icon: { type: String, default: "faCircleInfo" },
    isDefaultExpanded: { type: Boolean, default: false },
  },
  computed: {
    ...themesComputed,
    formattedSections(): FormattedSection[] {
      return [
        { title: this.section1Title, text: this.section1Text, icon: this.section1Icon },
        { title: this.section2Title, text: this.section2Text, icon: this.section2Icon },
        { title: this.section3Title, text: this.section3Text, icon: this.section3Icon },
        { title: this.section4Title, text: this.section4Text, icon: this.section4Icon },
        { title: this.section5Title, text: this.section5Text, icon: this.section5Icon },
      ]
        .filter((section) => section.title || section.text)
        .map((section) => ({
          title: section.title || "",
          text: section.text || "",
          icon: RegularIcons[section.icon as keyof typeof RegularIcons] || RegularIcons.faCircleInfo,
        }));
    },
  },
  data() {
    return {
      isExpanded: undefined,
    };
  },

  mounted() {
    this.isExpanded = this.isDefaultExpanded;
  },
  methods: {
    toggleExpanded() {
      this.isExpanded = !this.isExpanded;
    },
  },
});
</script>

<template>
  <div>
    <BlockPadding :padding="padding" />
    <b-card class="p-2 shadow-none text-left" style="border-radius: 4px !important; border: 1px solid #d0d5dd">
      <div class="d-flex flex-column gap-16">
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex justify-content-between align-items-center w-100">
            <BlockTitle :text="title" styles="h3" weight="bold" align="left" textColour="text-primary" />
            <font-awesome-icon
              :icon="isExpanded ? ['fas', 'chevron-up'] : ['fas', 'chevron-down']"
              class="text-primary transition-transform"
              @click="toggleExpanded"
              :style="{ cursor: 'pointer' }"
            />
          </div>
        </div>

        <b-collapse id="collapse-4" v-model="isExpanded" :visible="isDefaultExpanded">
          <BlockSubtitle :text="subtitle" align="left" styles="h5" weight="medium" textColour="text-dark" />
          <BlockPadding blockPadding="10" v-if="subtitle" />
          <BlockSubtitle
            :text="subtitle2"
            align="left"
            styles="h5"
            weight="medium"
            textColour="text-dark"
            v-if="subtitle2"
          />
          <BlockPadding blockPadding="10" v-if="subtitle2" />
          <div class="d-flex flex-column gap-16 pt-3">
            <div v-for="item in formattedSections" :key="`${item.title}-${item.text}`" class="d-flex flex-row gap-8">
              <div class="align-content-center justify-content-center px-1">
                <font-awesome-icon :icon="item.icon" class="text-primary w-24" />
              </div>
              <div class="align-content-center">
                <span :style="[theme.fontStyles]" class="text-md text-grey-700 font-weight-normal mb-0">
                  <span v-if="item.title" class="text-primary font-weight-semibold">{{ item.title }}: </span>
                  <span v-html="item.text"></span>
                </span>
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
    </b-card>
  </div>
</template>

<style scoped>
.gap-16 {
  gap: 16px;
}
.gap-8 {
  gap: 8px;
}
.w-24 {
  width: 24px;
  height: 24px;
}
</style>
